import { AppProps } from 'next/app';
import Router from 'next/router';
import React, { useCallback, useEffect } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'cropperjs/dist/cropper.css';
import { Provider as JotaiProvider } from 'jotai';
import { appWithTranslation } from 'next-i18next';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import TagManager from 'react-gtm-module';

import { LS_KEY_PCP_REFRESH_TOKEN, LS_KEY_PCP_REFRESH_TOKEN_EXP, checkValidSTPTeam, logout, validateAuthTokens } from '@/authentication';
import FullPageLoading from '@/components/FullPageLoading';
import theme from '@/design/theme';
import { featureControl } from '@/feature/toggle';
import { BasicProfileProvider } from '@/models/BasicProfile';
import NewVersionToast from '@/modules/NewVersionToast';
import { TryBeforeCommit } from '@/modules/TryBeforeCommit';
import { getUserBasicProfile as getUserBasicProfileAPI } from '@/services/common';
import '@/styles/citrus-loader.scss';
import '@/styles/reset.scss';
import chakraCodeModeManager from '@/utils/chakraCodeModeManager';
import { makeMSTeamsLoginUrl } from '@/utils/constants';
import isInIframe from '@/utils/isInIframe';

import { i18n, localePath } from '../../next-i18next.config';

// Should use NODE_ENV === 'development' for tree shaking on production
if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_MSW === 'true') {
  require('#/mocks');
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

export type ComponentProps = {
  skipAuth?: boolean;
  skipNewVersion?: boolean;
  skipResetCSS?: boolean; // Set to `true` to prevent styled-components from being affected by chakra resetCSS. Can be removed once styled-components is removed.
  skipI18n?: boolean;
  showTryBeforeCommit?: boolean;
  disableOsanoScript?: boolean;
};

type MyAppProps = AppProps & {
  Component: ComponentProps;
};

function MyApp({ Component, pageProps }: MyAppProps) {
  useEffect(() => {
    if (isInIframe()) {
      return;
    }
    // Avoid entering the pages during the login process in Teams app.
    const vendorId = sessionStorage.getItem('vendor_id');
    if (vendorId !== null && vendorId !== undefined) {
      window.location.href = makeMSTeamsLoginUrl({
        serverHost: window.location.origin,
        vendorId,
      });
    }
  }, []);

  return (
    <IsolateJotaiStateFromEachRequest>
      <ChakraProvider theme={theme} resetCSS={Component.skipResetCSS ? false : true} colorModeManager={chakraCodeModeManager}>
        <QueryClientProvider client={queryClient}>
          {!Component?.skipNewVersion && <NewVersionToast />}
          {Component?.showTryBeforeCommit && <TryBeforeCommit />}
          {Component.skipAuth ? (
            <Component {...pageProps} />
          ) : (
            <AuthUserProvider>
              <Component {...pageProps} />
            </AuthUserProvider>
          )}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ChakraProvider>
    </IsolateJotaiStateFromEachRequest>
  );
}

function IsolateJotaiStateFromEachRequest({ children }: { children: React.ReactNode }) {
  if (featureControl.getToggle('PCP_2315__JotaiAtomStateIsolation_app')) return <JotaiProvider>{children}</JotaiProvider>;

  return <>{children}</>;
}

type AuthUserProviderProps = { children?: React.ReactNode };
function AuthUserProvider({ children }: AuthUserProviderProps): React.JSX.Element {
  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: ['authUser'],
    queryFn: async () => {
      await validateAuthTokens();
      return await getUserBasicProfileAPI();
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isError) {
    logout();
  }

  const handleRouteChangeStart = useCallback(() => {
    const refreshToken = localStorage.getItem(LS_KEY_PCP_REFRESH_TOKEN);
    const refreshTokenExp = localStorage.getItem(LS_KEY_PCP_REFRESH_TOKEN_EXP) || 0;
    if (!refreshToken || new Date().getTime() > Number(refreshTokenExp)) {
      refetch();
    }
  }, [refetch]);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [handleRouteChangeStart]);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5QBCPHF' });
  }, []);

  if (!data || isFetching) return <FullPageLoading />;

  const isSplashtopPersonal = Boolean(data?.teams?.stp);
  if (isSplashtopPersonal && !checkValidSTPTeam()) {
    // Some critical situation may causes infinite re-fetching API loop, add this to prevent it.

    refetch();

    return <FullPageLoading />;
  }

  return <BasicProfileProvider basicProfile={data}>{children}</BasicProfileProvider>;
}

const FilterAppWithTranslation = (props: MyAppProps) => {
  const { Component } = props;

  // Skip server side i18n
  // mainly for ignoring team app pages, due to its special environment, can only use static build and client-side fetch i18n
  // and for some pages need static export, ex: web client
  if (Component.skipI18n) {
    return <MyApp {...props} />;
  }

  const AppWithTrans = appWithTranslation(MyApp, { i18n, localePath });
  return AppWithTrans(props);
};

export default FilterAppWithTranslation;
